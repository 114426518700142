/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(window).on("load resize", function equalHeights() {
          $('.row').each(function(){
            var highestBox = 0;
            $(this).find('.match-height').css("min-height", 0);

            $(this).find('.match-height').each(function() {
              if ($(this).outerHeight() > highestBox) {
                highestBox = $(this).outerHeight();
              }
            });
            $(this).find('.match-height').css("min-height", highestBox);
          });
          $('.row').each(function(){
            var highestBox = 0;
            $(this).find('.match-height2').css("min-height", 0);
            $(this).find('.match-height2').each(function() {
              if ($(this).outerHeight() > highestBox) {
                highestBox = $(this).outerHeight();
              }
            });
            $(this).find('.match-height2').css("min-height", highestBox);
          });
        }).resize();

        function createMoreMenu(menu, width) {
          if($(window).width() > width) {
            var w = 0; 
            var mw = $(menu).width() - 100; //100 = width of 'more' item
            var menuhtml = '';
            $(menu).children().each(function() {
              w += $(this).outerWidth(true);
              if (mw < w && !$(this).find('a').hasClass('more')) {
                menuhtml += $('<div>').append($(this).clone()).html();
                $(this).remove();
              }
            });
            if(menuhtml) {
              $(menu + " .dropdown.more>ul").html('');
              $(menu + " .dropdown.more>ul").append(menuhtml);
              $(menu + " .dropdown.more").removeClass('hidden');
            }
          }
        }

        $('header ul.more').each(function() {
          var menu = '#' + $(this).attr('id');
          var width = $(this).data('breakpoint') || 767;
          var originalMenu = $(menu).clone();
          $(window).on("load", function() { createMoreMenu(menu, width); });
          $(window).on("resize", function() { $(menu).replaceWith(originalMenu.clone()); createMoreMenu(menu, width); });
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.single-news .post-nav').click(function() {
          window.location = $(this).find('a').attr('href');
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#testimonials').owlCarousel({
          stagePadding: 30,
          loop: true,
          center: true,
          margin: 60,
          nav: true,
          items: 1,
          responsive: {
            768: {
              margin: 50
            },
            992: {
              items: 2,
              margin: 60,
              nav: true
            },
            1200: {
              margin: 60,
              items: 3
            },
            1600: {
              items: 3,
              margin: 30
            }
          }
        });

        $('#news').owlCarousel({
          stagePadding: 30,
          loop: true,
          center: false,
          margin: 60,
          nav: true,
          dots: true,
          items: 1,
          responsive: {
            992: {
              items: 2,
              margin: 30
            },
            1200: {
              items: 2
            },
            1600: {
              items: 2
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('.match-height').matchHeight();
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };



  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
